import React from 'react'
import { Box, Grid, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  sideTextHeader: {
    fontFamily: 'Roboto',
    fontSize: '2em',
  },
  sideTextSubHeader: {
    fontFamily: 'Roboto',
    fontSize: '2rem',
    fontStyle: 'italic',
  },
  sideText: {
    fontFamily: 'Roboto',
    marginTop: '8px',
    fontSize: '16px',
    lineHeight: '1.4rem',
  },
  testamonialBody: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    lineHeight: '1.4rem',
    paddingBottom: theme.spacing(2),
  },
  testamonialSource: {
    fontSize: '14px',
    lineHeight: '1.4rem',
    fontStyle: 'italic',
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(2),
  },
  testamonialSection: {
    color: 'white',
    background: `linear-gradient(75deg, #e0e0e0 0%,#7891e6 10%)`,
  },
}))
const Testamonial = (props: { body: string; source: string }) => {
  const classes = useStyles()
  return (
    <Box
      boxShadow={1}
      height={'100%'}
      padding={2}
      borderRadius={'8px'}
      position={'relative'}
      className={classes.testamonialSection}
    >
      <Typography className={classes.testamonialBody}>
        "{props.body}"
      </Typography>
      <Box marginRight={2}>
        <Typography className={classes.testamonialSource}>
          {props.source}
        </Typography>{' '}
      </Box>
    </Box>
  )
}
const TestamonialRow = () => (
  <Grid container item xs={12} spacing={4} justify={'space-around'}>
    <Grid item xs={3}>
      <Testamonial
        source={'Olga  Yakovlov - Agiloft'}
        body={'The true cost saving for us is in the quality of annotation'}
      />
    </Grid>
    <Grid item xs={3}>
      <Testamonial
        source={'Elena Chacko -  Harvard Law'}
        body={
          'Your system is much more convenient in terms of human supervision and organizing the data!'
        }
      />
    </Grid>
    <Grid item xs={3}>
      <Testamonial
        source={'John Bohannon -  PrimerAI'}
        body={
          "Just wanted to say, once again...  It's just great working with you and your team"
        }
      />
    </Grid>
  </Grid>
)
export default TestamonialRow
