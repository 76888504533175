import React from 'react'

import { Grid, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  sideTextHeader: {
    fontFamily: 'Roboto',
    fontSize: '2em',
  },
  sideTextSubHeader: {
    fontFamily: 'Roboto',
    fontSize: '2rem',
    fontStyle: 'italic',
  },
  sideText: {
    fontFamily: 'Roboto',
    marginTop: '8px',
    fontSize: '16px',
    lineHeight: '1.4rem',
  },
  testamonialBody: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    lineHeight: '1.4rem',
    paddingBottom: theme.spacing(2),
  },
  testamonialSource: {
    fontSize: '14px',
    lineHeight: '1.4rem',
    fontStyle: 'italic',
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(2),
  },
  testamonialSection: {
    color: 'white',
    background: `linear-gradient(75deg, #e0e0e0 0%,#7891e6 10%)`,
  },
}))
const RightPanelWhyCreditCard = () => {
  const classes = useStyles()
  return (
    <>
      <Typography variant={'h3'} className={classes.sideTextHeader}>
        A Credit Card ?!!
      </Typography>

      <Typography
        variant={'body2'}
        gutterBottom={true}
        className={classes.sideText}
      >
        Yes. You'll need a credit card to try LightTag. We know you're not used
        to it, but here's why we're asking:
      </Typography>
      <Typography
        variant={'body2'}
        gutterBottom={true}
        className={classes.sideText}
      >
        Unlike the majority of successful startups, LightTag is 100%
        bootstrapped. That means we need to be picky on how we spend our
        resources, just like you.
      </Typography>
      <Typography
        variant={'body2'}
        gutterBottom={true}
        className={classes.sideText}
      >
        Our pick is to give the best experience and attention possible to those
        that take text annotation as seriously as we do.
      </Typography>

      <Typography
        variant={'h3'}
        className={classes.sideTextSubHeader}
        color={'primary'}
      >
        No Risk
      </Typography>
      <Typography className={classes.sideText}>
        We want to know you're serious, but we're not asking for a leap of
        faith. All accounts come with a <strong>14 day free trial </strong>.
        Cancel before then and you won't be billed. We also send out two “trial
        ending” emails, so there’s <strong>no risk of unwanted charges</strong>.
        Oh, and we offer a 30 day money back guarantee.
      </Typography>
    </>
  )
}

export default RightPanelWhyCreditCard
