import RightPanelWhyCreditCard from './_rightPanelWithCC'
import RightPanelProgress from './rightPanelProgress'
import Grid from '@material-ui/core/Grid'
import RightPanelError from './rightPanelError'
import ReadyPanel from './_readyPannel'
import { TSignupDetails } from '../../_newSignup'
export type TSubmitStages = 'Init' | 'Provision' | 'Done' | 'Error'
interface Props {
  stage: TSubmitStages
  submitError?: string
  redirectURL?: string
  signupDetails: TSignupDetails
}
export const PayStatusBody : React.FunctionComponent<Props> = props => {
  let Body: JSX.Element
  switch (props.stage) {
    case 'Init':
      Body = <RightPanelWhyCreditCard />
      break
    case 'Provision':
      Body = <RightPanelProgress />
      break
    case 'Error':
      Body = <RightPanelError submitError={props.submitError} />
      break
    case 'Done':
      Body = (
        <ReadyPanel
          redirectURL={props.redirectURL}
          signupDetails={props.signupDetails}
        />
      )
      break
    default:
      Body = <div>Done</div>

  }
  return Body
}
const PayStatusColumn: React.FunctionComponent<Props> = props => {

  return (
    <Grid item container xs={6} md={6}>
      <Grid item xs={12}>
        <PayStatusBody {...props} />
      </Grid>
    </Grid>
  )
}
export default PayStatusColumn
