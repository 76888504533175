import React, { FunctionComponent } from 'react'
import Layout from '../../../components/layout'
import { Location as LocComp } from '@reach/router'

import queryString from 'query-string'

import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import Container from '@material-ui/core/Container'
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { loadStripe, StripeCardElement, Token } from '@stripe/stripe-js'
import { SignupClient } from '../../../utils/signupClient'
import { useMutation } from 'react-query'
import RightPanelWhyCreditCard from './_rightCol/_rightPanelWithCC'
import TestamonialRow from './_testamonialRow'
import RightPanelProgress from './_rightCol/rightPanelProgress'
import RightCol, { TSubmitStages } from './_rightCol/_payStatusCol'
import { gtagEvent } from '../../../utils/analytics'
import pricingPlans from '../../../utils/pricingPlans'
import { TSignupDetails } from '../_newSignup'
import { navigate } from 'gatsby'

const client = new SignupClient()

const stripePromise = loadStripe(process.env.STRIPE_PK_KEY)
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}
const useStyles = makeStyles(theme => ({
  sideTextHeader: {
    fontFamily: 'Roboto',
    fontSize: '2em',
  },
  sideTextSubHeader: {
    fontFamily: 'Roboto',
    fontSize: '2rem',
    fontStyle: 'italic',
  },
  sideText: {
    fontFamily: 'Roboto',
    marginTop: '8px',
    fontSize: '16px',
    lineHeight: '1.4rem',
  },
  testamonialBody: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    lineHeight: '1.4rem',
    paddingBottom: theme.spacing(2),
  },
  testamonialSource: {
    fontSize: '14px',
    lineHeight: '1.4rem',
    fontStyle: 'italic',
    position: 'absolute',
    bottom: theme.spacing(1),
    right: theme.spacing(2),
  },
  testamonialSection: {
    color: 'white',
    background: `linear-gradient(75deg, #e0e0e0 0%,#7891e6 10%)`,
  },
}))

interface Props {
  location: Location & { state: TSignupDetails }
}
const Pay: FunctionComponent<Props> = props => {
  const clasess = useStyles()
  const elements = useElements()
  const stripe = useStripe()

  const [stage, setStage] = React.useState<TSubmitStages>('Init')
  const [redirectURL, setRedirectURL] = React.useState<string | undefined>()
  const [submitError, setSubmitError] = React.useState<string | undefined>(
    undefined
  )
  const [cardError, setCardError] = React.useState<null | string>(null)
  const [canSubmit, setCanSubmit] = React.useState<boolean>(false)
  const { plansetId, funnelId } = queryString.parse(
    props.location.search
  ) as Record<any, string>
  const planset = pricingPlans.visiblePlansById[plansetId]
  const handleCardChange = (event: any) => {
    if (event.error) {
      setCardError(event.error.message)
    } else {
      setCardError(null)
    }
    if (event.complete) {
      setCanSubmit(true)
    } else {
      setCanSubmit(false)
    }
  }
  const [pay, payStatus] = useMutation(
    (data: { funnelId: string; plansetId: string; stripeToken: string }) =>
      client.launchPay(data),
    {
      onError: (err: any) => {
        if (err.reason) {
          setSubmitError(err.reason)
        }
        setStage('Error')
      },
      onSuccess: response => {
        setStage('Done')
        setRedirectURL(response.redirectURL)
      },
    }
  )

  const handleSubmit = async () => {
    if (elements && stripe) {
      setStage('Provision')
      const card = elements.getElement(CardElement)
      const result = await stripe.createToken(card as StripeCardElement)
      if (result.error) {
        setCardError(result.error.message as string)
        return
      } else {
        await pay({
          funnelId,
          plansetId,
          stripeToken: (result.token as Token).id,
        })
        gtagEvent('Payed', planset.monthlyPrice)
      }
    }
  }

  const signupDetails: TSignupDetails = props.location.state
  if (signupDetails === undefined) {
    return (
      <Layout
        backgroundColor="#FAFAFA"
        pageTitle="Signup For LightTag"
        pageDescription={"Get Started With LightTag's SaaS"}
      >
        <Container>
          <Typography>
            Something seems wrong. Please contact support at support@lighttag.io
          </Typography>
        </Container>
      </Layout>
    )
  }
  const { email, name, workspace } = signupDetails

  return (
    <Layout
      backgroundColor="#FAFAFA"
      pageTitle="Signup For LightTag"
      pageDescription={"Get Started With LightTag's SaaS"}
    >
      <Container>
        <Grid
          style={{ height: '100vh' }}
          container
          spacing={8}
          justify={'space-around'}
          alignItems={'center'}
        >
          <Grid item container xs={6} md={6}>
            <Box position={'relative'} borderRadius={4} boxShadow={3}>
              <div
                style={{
                  color: 'white',
                  background: '#7891e6',
                  padding: '1rem',
                  textAlign: 'center',
                  borderRadius: '4px',
                  fontWeight: 'bold',
                }}
              >
                <Typography variant={'h4'}>Add Your Credit Card</Typography>
              </div>

              <Typography
                variant={'body1'}
                gutterBottom={true}
                className={clasess.sideText}
                style={{ padding: '1rem', fontStyle: 'italic' }}
              >
                All accounts have a 14 day free trial. If you cancel before that
                time, you won't be billed.
              </Typography>
              <Typography
                variant={'body1'}
                gutterBottom={true}
                className={clasess.sideText}
                style={{ padding: '1rem', fontStyle: 'italic' }}
              >
                We'll notify you 3 days before your first charge. There’s no
                risk!
              </Typography>
              <Box padding={1}>
                <Box
                  border={0.5}
                  borderColor={'grey.500'}
                  padding={2}
                  borderRadius={1}
                >
                  <CardElement
                    onReady={el => {
                      el.focus()
                    }}
                    id="card-element"
                    options={CARD_ELEMENT_OPTIONS}
                    onChange={handleCardChange}
                  />
                </Box>
                <Typography variant="caption" color="error" role="alert">
                  {cardError}
                </Typography>
                <Button
                  onClick={handleSubmit}
                  variant={'contained'}
                  color={'primary'}
                  fullWidth
                  disabled={!canSubmit || payStatus.isLoading}
                  style={{ margin: '1rem 0' }}
                >
                  Start Your Free 14 Day Trial
                </Button>
              </Box>
            </Box>
          </Grid>
          <RightCol
            signupDetails={signupDetails}
            stage={stage}
            submitError={submitError}
            redirectURL={redirectURL}
          />
          <TestamonialRow />
        </Grid>
      </Container>
    </Layout>
  )
}
const WrappedPay = () => (
  <LocComp>
    {({ location, navigate }) => (
      <Elements stripe={stripePromise}>
        <Pay location={location} />
      </Elements>
    )}
  </LocComp>
)
export default WrappedPay
