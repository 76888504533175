import React from 'react'
import { Box, Typography, Button } from '@material-ui/core'
import { openPopupWidget, CalendlyEventListener } from 'react-calendly'
import { TSignupDetails } from '../../_newSignup'

const ReadyPanel: React.FunctionComponent<{
  redirectURL?: string
  signupDetails: TSignupDetails
}> = props => {
  const [scheduledOnboarding, setScheduleOnboarding] = React.useState<boolean>(
    false
  )
  // React.useEffect(() => {
  //   if (props.redirectURL) {
  //     setTimeout(
  //       () => window.location.replace(props.redirectURL as string),
  //       4000
  //     )
  //   }
  // }, [props.redirectURL])
  const { email, name } = props.signupDetails
  return (
    <CalendlyEventListener onEventScheduled={() => setScheduleOnboarding(true)}>
      <Box boxShadow={2}>
        <Box padding={1} bgcolor={'success.main'}>
          <Typography variant={'h4'} style={{ color: 'white' }}>
            We're Ready
          </Typography>
        </Box>
        <Box padding={2}>
          <Typography
            variant={'body2'}
            style={{ lineHeight: 1.5, margin: '1rem 0rem' }}
          >
            Your new LightTag instance is ready. We'd like to help you get
            started and get the most out of LightTag.
          </Typography>
          <Button
            variant={'contained'}
            color={scheduledOnboarding === false ? 'primary' : undefined}
            disabled={scheduledOnboarding}
            fullWidth={true}
            onClick={() => {
              openPopupWidget({
                url: 'https://calendly.com/lighttag/lighttag-onboarding',
                prefill: { name, email },
              })
            }}
          >
            Schedule Your Onboarding Call
          </Button>
          <Typography style={{ lineHeight: 1.5, margin: '1rem 0rem' }}>
            Can't wait to get started ?
          </Typography>
          <Button
            color={'primary'}
            variant={scheduledOnboarding ? 'contained' : 'outlined'}
            fullWidth
            href={props.redirectURL}
          >
            Launch LightTag
          </Button>
        </Box>
      </Box>
    </CalendlyEventListener>
  )
}
export default ReadyPanel
